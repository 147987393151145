function MainPageHdn() {
    return (
        <div>
            <p>
                This is an intelligent dictionary for Northern Haida, made by the <a href={"https://altlab.ualberta.ca"}>Alberta
                Language Technology Lab (ALTLab)</a>.
            </p>
        </div>
    );
}

export default MainPageHdn;
