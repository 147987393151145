function MainPageCwd() {
    return (
        <div>
            <p>
                This is an intelligent dictionary for Woods Cree, made by the <a href={"https://altlab.ualberta.ca"}>Alberta
                Language Technology Lab (ALTLab)</a>.
            </p>
        </div>
    );
}

export default MainPageCwd;
